<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <!-- <vuexy-logo /> -->
      <custom-logo />

      <h2 class="brand-text text-primary ml-1 mr-2">
        {{ navbarTitle }}
      </h2>
      <!-- <b-button
        size="sm"
        variant="primary"
        @click="logout"
      >
        <span class="mr-25 align-middle"> {{ $t("Logout") }}</span>
      </b-button> -->
      <b-dropdown
        :text="userProfile !== null ? userProfile.length> 0 ?`${userProfile[0].partnerUserInfoId.firstName} ${userProfile[0].partnerUserInfoId.lastName}` : '' : ''"
        variant="gradient-primary"
      >
        <!-- <b-dropdown-item>
          {{ $t("Company") }}
        </b-dropdown-item>
        <b-dropdown-item>
          {{ $t("Application") }}
        </b-dropdown-item>
        <b-dropdown-item>
          {{ $t("Staff") }}
        </b-dropdown-item> -->
        <!-- <b-dropdown-divider /> -->
        <b-dropdown-item @click="logout">
          {{ $t("Logout") }}
        </b-dropdown-item>
      </b-dropdown>
    </b-link>

    <div class="p-sm-3">
      <div class="w-full text-center">
        <b-row class="mt-5">
          <b-col
            v-for="(item, index) in userProfile"
            :key="index"
            cols="4"
          >
            <b-overlay
              :show="isLoading"
              rounded="sm"
            >
              <b-card :title="item.partnerUserInfoId.firstName.toString()+' '+item.partnerUserInfoId.lastName.toString()">
                <b-img
                  v-if="item.partnerUserInfoId.image"
                  ref="previewEl"
                  rounded
                  :src="item.partnerUserInfoId.image"
                  height="100"
                />
                <b-img
                  v-else
                  ref="previewEl"
                  :src="require('@/assets/images/blank/no_image.png')"
                  rounded
                  height="100"
                />
                <p v-if="item.partnerUserId.applicationId !== null">
                  {{ item.partnerUserId.applicationId.name }}
                </p>
                <b-button
                  class="mt-2"
                  size="sm"
                  variant="primary"
                  @click="selectUser(item)"
                >
                  <span class="mr-25 align-middle"> {{ $t("SELECT") }}</span>
                </b-button>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>

        <!-- <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button> -->

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton,
  BImg,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BAvatar,
//   BDropdownDivider,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'

import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, storeGroceryAbility, storeFoodAbility, storeQueueAbility, driverDeliveryAbility, driverCallAbility, servicesAbility,
} from '@/libs/acl/config'
import storeModule from '@/store/SelectUser/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'SelectUser'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    CustomLogo,
    BLink,
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // BDropdownDivider,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      isLoading: false,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    userProfile() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    themeConfig() {
      return $themeConfig
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }

    // const obj = {
    //   currentPage: this.currentPage,
    //   pageSize: this.perPage,
    // }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, {
        token: this.$route.query.token,
        id: this.$route.query.id,
      })
      .then(result => {
        console.log('fetch success :', result)
        useJwt.logout()

        if (result.data.data.length === 1) {
          this.selectUser(result.data.data[0])
        }
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    useJwt.logout()
    // Reset ability
    this.$ability.update(initialAbility)

    store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    store.commit('appConfig/RESET_NAVBAR_TITLE')
    store.commit('appConfig/RESET_NAVBAR_IMAGE')
  },
  methods: {
    showToast(variant, position, title, icon) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    selectUser(item) {
      store
        .dispatch(`${STORE_MODULE_NAME}/loginUser`, {
          token: this.$route.query.token,
          id: this.$route.query.id,
          user: item,
        })
        .then(response => {
          this.show = false
          console.log('fetch success :', response)
          //  eslint-disable-next-line no-unused-vars
          const { accessToken, userData, refreshToken } = response.data

          useJwt.setToken(accessToken)
          useJwt.setRefreshToken(refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          if (userData.applicationId) {
            localStorage.setItem('applicationId', userData.applicationId)
          }
          if (userData.companyId) {
            localStorage.setItem('companyId', userData.companyId)
          }
          if (userData.franchiseId) {
            localStorage.setItem('franchiseId', userData.franchiseId)
          }
          if (this.$route.query.profile) {
            this.$router.replace({ name: 'select-profile', query: { id: this.$route.query.profile } }).catch(err => { console.log(err) })
          } else {
            this.$router.replace({ name: 'select-profile' }).catch(err => { console.log(err) })
          }
        })
        .catch(error => {
          this.show = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      useJwt.logout()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace({ name: 'auth-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
